/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const ViewReserveModal = ({ viewReserveData }) => {

    const token = localStorage.getItem("token");
    const { t, i18n } = useTranslation(); //for translation

    console.log('viewReserveData', viewReserveData);

  return (
    <div className="modal fade" id="reservationDetailsModal" tabindex="-1" aria-labelledby="reservationDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content border-0 rounded-0">
            <div className="modal-header border-0">
                <h3 className="text-secondary mb-0 d-flex" id="reservationDetailsModalLabel">
                <span className="d-block">
                    {t("Reserve")} : {' '} {viewReserveData?viewReserveData.customerid : ''}                    
                </span>
                {viewReserveData?.isConsecutive ? 
                            (<span
                                    className="ms-1 material-symbols-outlined icon-md text-secondary"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Consecutive reserve"
                                >
                                    copyright
                                </span>) : ''}
                </h3>
                <button
                type="button"
                className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">

                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="reserveDate">{t("Date")}</label>
                            <p>{viewReserveData?viewReserveData.startdate : ''}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="reserveShift">{t("Shift")} / {t("Time")}</label>
                            <p>
                                <span className="pe-2">({viewReserveData?viewReserveData.shiftlabel : ''})</span>
                                <span className="pe-2 fw-bold">{viewReserveData?viewReserveData.starttime : ''}</span>
                                -
                                <span className="fw-bold">{viewReserveData?viewReserveData.endtime : ''}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="customerId">ID</label>
                            <p>{viewReserveData?.customerid ?? ''}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="isConsecutive">{t("Is Consecutive")}</label>
                            <p>{viewReserveData?.isConsecutive ? t("Yes") : t("No")}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="activity">{t("Activity")}</label>
                            <p>{viewReserveData?.activitycode?.code??''} - {viewReserveData?.activitycode?.name??''}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="location">{t("Location")}</label>
                            <p>{viewReserveData?.location ?? ''}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="town">{t("Town")}</label>
                            <p>{viewReserveData?.town ?? ''}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="beneficiary">{t("Beneficiary")}</label>
                            <p>{viewReserveData?.beneficiary?.name ?? ''}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="grade">{t("Grade")}</label>
                            <p>{viewReserveData?.grade ?? ''}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="comments">{t("Comments")}</label>
                            <p>{viewReserveData?.comments ?? ''}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="modal-footer border-0">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("Close")}</button>
            </div>
            </div>
        </div>
        </div>
  );
};

export default ViewReserveModal;
