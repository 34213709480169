/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

import { useParams } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import option for availibility
import availibilityOptions from "data/Prod/CalendarAvailability.json";
import Shifts from "data/Prod/Shifts.json";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { getWeekdaysBetweenDates } from "helpers/TimeCalculation";

const SaveSchedulePopup = ({ reloadCalender, setReloadCalender }) => {
  const token = localStorage.getItem("token"); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const { setReloadCalendarEvents } = useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [reserveList, setReserveList] = useState([]);

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  //use state for shift
  const [shiftValue, setShiftValue] = useState(null);
  const [shiftData, setShiftData] = useState(null);

  const [clientValue, setClientValue] = useState(null);
  const [conceptValue, setConceptValue] = useState(null);
  const [reserveValue, setReserveValue] = useState(null);

  const [selectedDays, setSelectedDays] = useState([]);

  const [isSending, setIsSending] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const today = new Date().toISOString().split("T")[0];

  //function for get current time and set by defualt
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const timenow = `${hours}:${minutes}`;

    return timenow;
  };

  /* useState hook for input fields */
  const [scheduleInputs, setScheduleInputs] = useState({
    name: "Available",
    date: today,
    starttime: getCurrentTime(),
    endtime: getCurrentTime(),
    description: "",
    enddate: "",
  });

  //function for get all clients
  const getAllClient = async () => {
    try {
      let clientRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;

      let benificiaryRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=beneficiary`;

      const clientResponse = await getData(clientRequestUrl);

      const beneficiaryResponse = await getData(benificiaryRequestUrl);

      if (clientResponse.status && beneficiaryResponse.status) {
        let clientArr = [];
        clientArr = [...clientResponse.data, ...beneficiaryResponse.data];
        setClientList(clientArr);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all concept
  const getAllConceptList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_CONCEPT + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setConceptList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all reserve
  const getAllReserveList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}&scheduleuser=${params.id}`;

      console.log(
        "Reserve list url for profile schedule tab ========>",
        requestUrl
      );

      const response = await getData(requestUrl);

      console.log(
        "Reserve list response for profile schedule tab ========>",
        response
      );

      if (response.status) {
        setReserveList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for Availability selection
  const availabilitySelectionHandler = (val) => {
    setScheduleInputs((prevState) => ({
      ...prevState,
      name: val.label,
    }));

    setAvailabilityValue(val);
    setAvailabilityData(val.value);

    for (let shiftData of Shifts) {
      if (shiftData.id == "1") {
        shiftSelectionHandler(shiftData);
      }
    }
  };

  //function for shift selection
  const shiftSelectionHandler = (val) => {
    console.log("shift val", val);

    setShiftValue(val);
    setShiftData(val.value);
    setScheduleInputs((prevState) => ({
      ...prevState,
      starttime: val.start,
      endtime: val.end,
    }));
  };

  //function for day checkbox value
  const dayCheckboxChange = (event) => {
    setErrorMessage("");
    const { value, checked } = event.target;
    if (checked) {
      setSelectedDays((prevSelectedDays) => [...prevSelectedDays, value]);
    } else {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day !== value)
      );
    }
  };
  /* Hook for validation start */
  const [validation, setValidation] = useState({
    dateWarning: false,
    timeWarning: false,
    shiftWarning: false,
  });

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (
      scheduleInputs.enddate !== "" &&
      scheduleInputs.date > scheduleInputs.enddate
    ) {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (scheduleInputs.starttime > scheduleInputs.endtime) {
      setValidation((prevState) => ({ ...prevState, timeWarning: true }));
      isValid = false;
    }

    if (shiftValue == null && shiftData == null) {
      setValidation((prevState) => ({ ...prevState, shiftWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* function for validation ends */

  /* function to add calender event */
  const addScheduleHandler = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      try {
        let selectedWeekdays = [];

        if (scheduleInputs.enddate !== "") {
          const startDate = new Date(scheduleInputs.date);
          const endDate = new Date(scheduleInputs.enddate);

          selectedWeekdays = getWeekdaysBetweenDates(
            startDate,
            endDate,
            selectedDays
          );
        }

        // console.log("selectedWeekdays", selectedWeekdays);

        let scheduleData = {
          user: params.id,
          name: scheduleInputs.name,
          date: scheduleInputs.date,
          starttime: scheduleInputs.starttime,
          endtime: scheduleInputs.endtime,
          description: scheduleInputs.description,
          enddate: scheduleInputs.enddate,
          availability: availabilityData,
          shift: shiftData,
          days: selectedDays || [],
          conceptid: conceptValue,
          clientid: clientValue,
          reserveid: reserveValue,
          selectedweekdays: selectedWeekdays,
        };

        console.log(`Schedule Data===========`, scheduleData);

        setErrorMessage("");

        setIsSending(true);
        let requestUrl =
          url.API_BASEURL + url.API_ADD_NEW_SCHEDULE + `?token=${token}`;

        const response = await postData(requestUrl, scheduleData);

        console.log(`response submiting schedule data ========>>`, response);
        setIsSending(false);
        if (response.status) {
          if (response.nonavailablemessage == "") {
            resetHandler();
            bootstrap.Offcanvas.getInstance(
              document.querySelector("#saveSchedulePopup")
            ).hide();
          } else {
            setErrorMessage(
              `${response.nonavailablemessage} ${t(
                "dates are not avalible to save new record"
              )}`
            );
          }
          setReloadCalendarEvents(true);
        } else {
          if (response.nonavailablemessage !== "") {
            setErrorMessage(
              `${response.nonavailablemessage} ${t("with")} ${
                scheduleInputs.starttime
              } ${t("are not avalible to save new record")}`
            );
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setErrorMessage(t("Fill up all fields. Please Try again!"));
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setScheduleInputs({
      name: "Available",
      date: today,
      starttime: getCurrentTime(),
      endtime: getCurrentTime(),
      description: "",
      enddate: "",
    });

    for (let availibilityData of availibilityOptions) {
      if (availibilityData.value == "1") {
        availabilitySelectionHandler(availibilityData);
      }
    }

    for (let shiftData of Shifts) {
      if (shiftData.id == "1") {
        shiftSelectionHandler(shiftData);
      }
    }
    setSelectedDays([]);
    setClientValue(null);
    setConceptValue(null);
    setReserveValue(null);
    setValidation({
      dateWarning: false,
      timeWarning: false,
    });
    setErrorMessage("");
  };

  /* hook to reload list */
  useEffect(() => {
    if (reloadCalender) {
      getAllClient();
      getAllConceptList();
      getAllReserveList();
      for (let availibilityData of availibilityOptions) {
        if (availibilityData.value == "1") {
          availabilitySelectionHandler(availibilityData);
        }
      }
      for (let shiftData of Shifts) {
        if (shiftData.id == "1") {
          shiftSelectionHandler(shiftData);
        }
      }
      setReloadCalender(false);
    }
  }, [reloadCalender]);

  //use effect for set default value of avilibility
  useEffect(() => {
    getAllClient();
    getAllConceptList();
    getAllReserveList();
    for (let availibilityData of availibilityOptions) {
      if (availibilityData.value == "1") {
        availabilitySelectionHandler(availibilityData);
      }
    }
  }, []);

  return (
    <div className="offcanvas offcanvas-end border-0" id="saveSchedulePopup">
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("Add Calendar Record")}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={addScheduleHandler}>
          <div className="calendar_data_item">
            {/* ------- availibility and shift section start ------ */}
            <div className="form-group mb-4">
              <div className="row">
                {/* ------ Availability section start ----- */}
                <div className="col-lg-6">
                  <label htmlFor="record">{t("Set record as")} *</label>
                  <Select
                    options={availibilityOptions}
                    value={availabilityValue}
                    onChange={(val) => {
                      availabilitySelectionHandler(val);
                      setErrorMessage("");
                    }}
                  />
                </div>
                {/* ------ Availability section end ----- */}

                {/* ------ Availability section start ----- */}
                {availabilityData != "2" ? (
                  <div className="col-lg-6">
                    <label htmlFor="shift">{t("Select shift/turn")} *</label>
                    <Select
                      options={Shifts}
                      value={shiftValue}
                      onChange={(val) => {
                        shiftSelectionHandler(val);
                        setErrorMessage("");
                      }}
                    />

                    {/* date compare warning */}
                    {validation.shiftWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please select shift")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* ------ Availability section end ----- */}
              </div>
            </div>
            {/* ------- availibility and shift section start ------ */}

            {/* ========== date section start ============ */}
            <div className="form-group mb-4">
              <div className="row">
                {/* -------- start date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t("Start Date")} *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.date}
                    onChange={(val) => {
                      setScheduleInputs((prevState) => ({
                        ...prevState,
                        date: val.target.value,
                      }));

                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                      setErrorMessage("");
                    }}
                  />
                  {/* date compare warning */}
                  {validation.dateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>
                          {t("End date should be greater than start date")}!
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {/* -------- start date section end --------- */}

                {/* -------- end date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t("End Date")}</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.enddate}
                    onChange={(val) => {
                      setScheduleInputs((prevState) => ({
                        ...prevState,
                        enddate: val.target.value,
                      }));
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                      setErrorMessage("");
                    }}
                  />
                </div>
                {/* -------- end date section end --------- */}
              </div>
            </div>
            {/* ========== date section end ============ */}

            <div className="form-group mb-4">
              {/* =============== select days start ==================  */}
              {scheduleInputs.enddate == "" ||
              scheduleInputs.date == "" ? null : (
                <div className="row">
                  <div className="col">
                    <label htmlFor="repeatDays">{t("Repeat On")}</label>
                    <div className="repeat-days-container">
                      <div className="d-flex overflow-auto py-2">
                        {/* ------- monday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedMonday}
                            // onChange={(e) => {
                            //   setSelectedMonday(e.target.checked);
                            // }}
                            value="monday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Monday")}
                          </label>
                        </div>
                        {/* ------- monday section end ------ */}

                        {/* ------- tuesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedTuesday}
                            // onChange={(e) => {
                            //   setSelectedTuesday(e.target.checked);
                            // }}
                            value="tuesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Tuesday")}
                          </label>
                        </div>
                        {/* ------- tuesday section end ------ */}

                        {/* ------- Wednesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedWednesday}
                            // onChange={(e) => {
                            //   setSelectedWednesday(e.target.checked);
                            // }}
                            value="wednesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Wednesday")}
                          </label>
                        </div>
                        {/* ------- Wednesday section end ------ */}

                        {/* ------- Thursday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedThursday}
                            // onChange={(e) => {
                            //   setSelectedThursday(e.target.checked);
                            // }}
                            value="thursday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Thursday")}
                          </label>
                        </div>
                        {/* ------- Thursday section end ------ */}

                        {/* ------- Friday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedFriday}
                            // onChange={(e) => {
                            //   setSelectedFriday(e.target.checked);
                            // }}
                            value="friday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Friday")}
                          </label>
                        </div>
                        {/* ------- Friday section end ------ */}

                        {/* ------- Saturday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedSaturday}
                            // onChange={(e) => {
                            //   setSelectedSaturday(e.target.checked);
                            // }}
                            value="saturday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Saturday")}
                          </label>
                        </div>
                        {/* ------- Saturday section end ------ */}

                        {/* ------- sunday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedSunday}
                            // onChange={(e) => {
                            //   setSelectedSunday(e.target.checked);
                            // }}
                            value="sunday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {t("Sunday")}
                          </label>
                        </div>
                        {/* ------- sunday section end ------ */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* =============== select days end ==================  */}
            </div>

            {/* ============== time section starts =================== */}
            {availabilityData != "2" ? (
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------ start time section start ----- */}
                  <div className="col-lg-6">
                    <label htmlFor="startTime">{t("Start Time")} *</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="HH : MM"
                      value={scheduleInputs.starttime}
                      onChange={(val) => {
                        setScheduleInputs((prevState) => ({
                          ...prevState,
                          starttime: val.target.value,
                        }));
                        setValidation((prevState) => ({
                          ...prevState,
                          timeWarning: false,
                        }));
                        setErrorMessage("");
                      }}
                    />
                    {/* time compare warning */}
                    {validation.timeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>
                            {t("End time should be greater than start time")}!
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* ------ start time section end ----- */}

                  {/* ------ end time section start ----- */}
                  <div className="col-lg-6">
                    <label htmlFor="endTime">{t("End Time")} *</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="HH : MM"
                      value={scheduleInputs.endtime}
                      onChange={(val) => {
                        setScheduleInputs((prevState) => ({
                          ...prevState,
                          endtime: val.target.value,
                        }));
                        setValidation((prevState) => ({
                          ...prevState,
                          timeWarning: false,
                        }));
                        setErrorMessage("");
                      }}
                    />
                  </div>
                  {/* ------ end time section start ----- */}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* ============== time section ends =================== */}

            {/* ========== title section start ============ */}
            <div className="form-group mb-4">
              <label>{t("Display Title")} *</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Display Title")}
                value={scheduleInputs.name}
                onChange={(val) => {
                  setScheduleInputs((prevState) => ({
                    ...prevState,
                    name: val.target.value,
                  }));
                  setErrorMessage("");
                }}
              />
            </div>
            {/* ========== title section end ============ */}

            {/* ------- select concept section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="conceptselect">{t("Select concept")}</label>
              <Select
                options={conceptList}
                placeholder={t("Select concept")}
                value={conceptValue}
                onChange={(val) => {
                  setConceptValue(val);
                  setErrorMessage("");
                }}
              />
            </div>
            {/* ------- select concept section end ------ */}

            {/* ------- select client section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="clientselect">{t("Select client")}</label>
              <Select
                options={clientList}
                placeholder={t("Select client")}
                value={clientValue}
                onChange={(val) => {
                  setClientValue(val);
                  setErrorMessage("");
                }}
              />
            </div>
            {/* ------- select client section end ------ */}

            {/* ------- select reserve section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="reserveselect">{t("Select reserve")}</label>
              <Select
                options={reserveList}
                placeholder={t("Select reserve")}
                value={reserveValue}
                onChange={(val) => {
                  setReserveValue(val);
                  setErrorMessage("");
                }}
              />
            </div>
            {/* ------- select reserve section end ------ */}

            {/* =============== description section starts ============= */}
            <div className="form-group mb-4">
              <label htmlFor="educatorComments">{t("Description")}</label>
              <textarea
                name="educatorComments"
                id="educatorComments"
                cols="30"
                rows="4"
                className="form-control"
                placeholder={t("Enter Description")}
                value={scheduleInputs.description}
                onChange={(val) => {
                  setScheduleInputs((prevState) => ({
                    ...prevState,
                    description: val.target.value,
                  }));
                  setErrorMessage("");
                }}
              ></textarea>
            </div>
            {/* =============== description section ends ============= */}
          </div>

          <div className="action d-flex align-items-center gap-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
            >
              {t("Save")}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {errorMessage == "" ? null : (
              <p className="warring">
                <i className="material-icons-outlined">warning</i>
                <span>{errorMessage}</span>
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveSchedulePopup;
